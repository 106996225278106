import React, { useState, useEffect } from "react";
import { isMobileTablet } from "../components/utils/detectDevice";

export const About = (props) => {
  const [animDetected, setAnimDetected] = useState(false);

  const getposition = () => {
    const t = window.scrollY;
    if (t >= (isMobileTablet ? 600 : 390)) {
      setAnimDetected(true);
    }
  };
  useEffect(() => {
    if (!animDetected) {
      window.addEventListener("scroll", getposition);
    }
  }, []);

  return (
    <div id="about">
      <div
        className={`container aboutOpacity ${animDetected && "aboutAnim"}`}
      >
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Qui sommes-nous ?</h2>
              <p>
                Tech digital est une <strong>agence web</strong> qui opère dans
                les différents métiers du <strong>digital</strong> dans le but
                de satisfaire les besoins de ses clients. La réputation de notre
                agence web est le fruit d’une implication personnelle de chacun
                de nos collaborateurs qui partagent les valeurs de l’agence et
                qui manifestent, au quotidien, le désir de satisfaire les
                attentes de nos différents partenaires. Nous vous accompagnons
                de la <strong>conception graphique</strong> et{" "}
                <strong>technique</strong> jusqu’à la{" "}
                <strong>maintenance informatique</strong>.
                <br />
                <br />
                Nous intervenons sur le secteur de :
                <br />
                <strong>
                  Mandelieu - Cannes - Le Cannet - Mougins - Antibes - Pégomas - Mougins -
                  Grasse - Nice
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
